<template>
  <div class="worker-id-card-img">
    <div class="img-item">
      <div class="img-item-ladel">正面</div>
      <div class="img-c" :style="sizeStyle" v-loadingx="front.loading" @click="clickImg('front')">
        <img :src="front.src" v-if="front.src" :style="maxSizeStyle" style="width: 100%;"/>
        <div v-else class="no-img">
          <div>未上传身份证正面</div>
          <div>点击上传</div>
        </div>
      </div>
    </div>
    <div class="img-item">
      <div class="img-item-ladel">反面</div>
      <div class="img-c" :style="sizeStyle" v-loadingx="back.loading" @click="clickImg('back')">
        <img :src="back.src" v-if="back.src" :style="maxSizeStyle" style="width: 100%;"/>
        <div v-else class="no-img">
          <div>未上传身份证反面</div>
          <div>点击上传</div>
        </div>
      </div>
    </div>
    <input type="file" style="display: none;" @change="startUpload" ref="upload">
  </div>
</template>

<script>
import {
  fileRequest
} from '@/api'

export default {
  props: {
    workerData: {
      type: Object
    }
  },
  data () {
    return {
      sizeStyle: {},
      maxSizeStyle: {},
      front: {
        loading: false,
        src: null
      },
      back: {
        loading: false,
        src: null
      },
      frontFileId: null,
      backFileId: null,
      uploadType: null
    }
  },
  mounted() {
    let width = this.$el.clientWidth
    this.sizeStyle = {
      width: width + 'px',
      height: (width / 1.58) + 'px'
    }
    this.maxSizeStyle = {
      'max-width': width + 'px',
      'max-height': (width / 1.58) + 'px'
    }
  },
  watch: {
    workerData: {
      handler () {
        this.frontFileId = this.workerData && this.workerData.idCardFrontFileId ? this.workerData.idCardFrontFileId : null
        this.backFileId = this.workerData && this.workerData.idCardBackFileId ? this.workerData.idCardBackFileId : null
      },
      immediate: true,
      deep: true
    },
    frontFileId: {
      handler () {
        this.loadFile('front')
      },
      immediate: true,
      deep: true
    },
    backFileId: {
      handler () {
        this.loadFile('back')
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    clickImg (type) {
      this.uploadType = type
      this.$refs.upload.click()
    },
    async startUpload () {
      let files = this.$refs.upload.files
      if (files.length) {
        let file = files[0]
        if (file.type && file.type.includes('image')) {
          let hisFileId = this[this.uploadType + 'FileId']
          if (hisFileId) {
            let res = await this.$dialog.confirm('该照片将被覆盖，是否继续操作?')
            if (!res) {
              return
            }
          }
          this[this.uploadType].loading = true
          let parm = new FormData()
          parm.append('file', file)
          let fileData = await fileRequest.upload(parm)
          await this.$emit('idcardFileChange', {
            type: this.uploadType,
            fileData: fileData
          })
          // if (hisFileId) {
          //   await fileRequest.del({'id': hisFileId})
          // }
          this.$notice.success({
            title: '系统提示',
            desc: '上传完成。'
          })
          this[this.uploadType].loading = false
          this[this.uploadType + 'FileId'] = fileData.id
        } else {
          this.$notice.info({
            title: '系统提示',
            desc: '请选择图片文件。'
          })
        }
      }
    },
    async loadFile (type) {
      this[type].src = null
      if (!this[type + 'FileId']) {
        return
      }
      this[type].loading = true
      try {
        let fileData = await fileRequest.download({
          id: this[type + 'FileId']
        })
        if (fileData) {
          const src = await new Promise(resolve => {
            let reader = new FileReader()
            reader.readAsDataURL(fileData)
            reader.onload = () => resolve(reader.result)
          })
          this[type].src = src
        } else {
          this[type].src = null
        }
      } catch (error) {
        this[type].src = null
      }
      this[type].loading = false
    }
  }
}
</script>

<style scoped lang="less">
.worker-id-card-img {
  .img-item-ladel {
    font-size: 14px;
    color: #646566;
    margin: 20px 10px 10px;
  }
}
.img-c {
  justify-content: center;
  align-items: center;
  display: flex;
  border: 1px solid #eee;
  .no-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #999;
  }
}

</style>
