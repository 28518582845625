<template>
  <page-base :show-btn="true" @save="save(null)" @submit="submit">
    <div class="idcard-block">
    <div style="padding: 10px 20px;font-size: 14px;border-bottom: 1px solid #ebedf0;"><span style="color: #646566;margin-right: 20px;">身份证号:</span><span style="color: #323233">{{draftData ? draftData.idNo : ''}}</span></div>
      <worker-id-card-img
        :worker-data="draftData"
        @idcardFileChange="idcardFileChange">
      </worker-id-card-img>
    </div>
  </page-base>
</template>

<script>
import WorkerIdCardImg from './workerIdCardImg'
import PageBase from './pageBase'

import {
  getBirthByIdNo,
  getAge
} from '@/syslib/tools'

import {
  dateOperating
} from '@/fmlib'

import {
  dataAppleRequest,
  workerRequest
} from '../../../api'

export default {
  components: {
    WorkerIdCardImg,
    PageBase
  },
  data () {
    return {
      formData: {},
      formCheck: true,
      isChange: false,
      workerId: null,
      data: {},
    }
  },
  activated () {
    this.workerId = this.$router.history.current.query.workerId
    this.loadData()
  },
  computed: {
    sourceData () {
      let data = this.data && this.data.sourceInfo ? this.data.sourceInfo : null
      if (data && data.birth) {
        data.age = getAge(data.birth)
      }
      return data
    },
    draftData () {
      let data = this.data ? this.data.editInfo || this.data.sourceInfo : null
      if (data && data.birth) {
        data.age = getAge(data.birth)
      }
      return data
    },
    waitData () {
      return this.data && this.data.modifyApplyInfo ? this.data.modifyApplyInfo : null
    },
    waitKeys () {
      return this.data && this.data.modifyApply && this.data.modifyApply.newData ? Object.keys(JSON.parse(this.data.modifyApply.newData)) : []
    },
    formParms () {
      let data = [
        {
          type: 'input',
          label: '姓名',
          key: 'name'
        },
        {
          type: 'select',
          label: '性别',
          key: 'sex',
          columns: [{value: '男', label: '男'}, {value: '女', label: '女'}]
        },
        {
          type: 'select',
          label: '民族',
          key: 'clan',
          search: true,
          columns: ['汉族', '蒙古族', '回族', '藏族', '维吾尔族', '苗族', '彝族', '壮族', '布依族', '朝鲜族', '满族', '侗族', '瑶族', '白族', '土家族', '哈尼族', '哈萨克族', '傣族', '黎族', '僳僳族', '佤族', '畲族', '高山族', '拉祜族', '水族', '东乡族', '纳西族', '景颇族', '柯尔克孜族', '土族', '达斡尔族', '仫佬族', '羌族', '布朗族', '撒拉族', '毛南族', '仡佬族', '锡伯族', '阿昌族', '普米族', '塔吉克族', '怒族', '乌孜别克族', '俄罗斯族', '鄂温克族', '德昂族', '保安族', '裕固族', '京族', '塔塔尔族', '独龙族', '鄂伦春族', '赫哲族', '门巴族', '珞巴族', '基诺族'].map(v => {
            return {value: v, label: v}
          })
        },
        {
          type: 'select',
          label: '政治面貌',
          key: 'politicalStatus',
          search: true,
          columns: ['中共党员', '中共预备党员', '共青团员', '民革党员', '民盟盟员', '民建会员', '民进会员', '农工党党员', '致公党党员', '九三学社社员', '台盟盟员', '无党派人士', '群众', '其他'].map(v => {
            return {value: v, label: v}
          })
        },
        {
          type: 'input',
          label: '身份证号码',
          key: 'idNo'
        },
        {
          type: 'datePicker',
          label: '出生年月',
          key: 'birth',
          format: (data) => {
            return data.birth ? dateOperating.computeDay({days: 0, date: data.birth, format: 'yy-mm-dd'}) : null
          }
        },
        {
          type: 'input',
          label: '年龄',
          disabled: true,
          key: 'age'
        },
        {
          type: 'select',
          label: '个人身份',
          disabled: this.workerApplyEditType1,
          key: 'sf',
          columns: [{value: '应届毕业生', label: '应届毕业生'}, {value: '社会人员', label: '社会人员'}]
        },
        {
          type: 'select',
          label: '学历',
          key: 'education',
          columns: ['博士研究生', '硕士研究生', '本科', '大专', '中专', '高中', '初中', '小学'].map(v => {
            return {value: v, label: v}
          })
        },
        {
          type: 'input',
          label: '籍贯',
          key: 'hometown'
        },
        {
          type: 'input',
          label: '联系电话',
          key: 'phone'
        },
        {
          type: 'select',
          label: '是否特殊人才',
          key: 'agreementCode',
          disabled: this.workerApplyEditType1,
          columns: [{value: '是', label: '是'}, {value: '否', label: '否'}]
        },
        {
          type: 'select',
          label: '是否港澳台及外籍人士',
          key: 'graduatedSchool',
          columns: [{value: '是', label: '是'}, {value: '否', label: '否'}]
        }
      ]
      if (!this.workerShowSelfId) {
        data = data.filter(v => v.key !== 'sf')
      }
      return data
    },
    workerApplyEditType1 () {
      return this.$store.getters.sysConfig['workerApplyEditType1']
    },
    workerShowSelfId () {
      return this.$store.getters.sysConfig['workerShowSelfId']
    }
  },
  watch: {
    draftData: {
      handler (data) {
        this.formData = data ? JSON.parse(JSON.stringify(data)) : {}
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async idcardFileChange (parm) {
      let idData = {}
      if (parm.type === 'front') {
        idData.idCardFrontFileId = parm.fileData.id
      }
      if (parm.type === 'back') {
        idData.idCardBackFileId = parm.fileData.id
      }
      let data = Object.assign({}, this.formData, idData)
      this.formChange({
        data,
        pass: true
      })
      await this.save()
    },
    async loadData () {
      if (this.workerId) {
        let data = await workerRequest.getEdit({
          workerId: this.workerId
        })
        if (data.length !== 1) {
          this.$notice.info({
            title: '系统提示',
            desc: '数据异常，请联系管理员'
          })
        } else {
          this.data = data[0]
        }
      }
    },
    onChange (key, value) {
      let rData = {}
      if (key === 'idNo') {
        let birth = getBirthByIdNo(value)
        if (birth) {
          rData.birth = birth
          rData.age = getAge(birth)
        }
      }
      if (key === 'birth') {
        rData.age = getAge(value)
      }
      return rData
    },
    testEdited () {
      return this.isChange
    },
    async save (type) {
      if (!this.formCheck) {
        this.$notice.info({
          title: '系统提示',
          desc: '表单验证未通过'
        })
        throw new Error('表单验证未通过')
      }
      let saveData = JSON.parse(JSON.stringify(this.formData))
      if (saveData && Array.isArray(saveData.positionIds)) {
        saveData.positionIds = saveData.positionIds.join(',')
      }
      await workerRequest.saveEdit(Object.assign({}, this.formData, {
        dataId: this.formData.id,
        id: undefined
      }))
      this.isChange = false
      if (type !== 'submit') {
        this.loadData()
        this.$notice.info({
          title: '系统提示',
          desc: '保存成功'
        })
      }
      return true
    },
    async submit () {
      let data = await this.save('submit')
      if (data) {
        await dataAppleRequest.submitByWorkerId(this.workerId, {
          dataType: 'worker'
        })
        this.loadData()
        this.$notice.info({
          title: '系统提示',
          desc: '提交成功'
        })
      }
    },
    formChange (data) {
      this.isChange = true
      this.formData = data.data
      this.formCheck = data.pass
    }
  }
}
</script>

<style lang="less" scope>
.idcard-block {
  // width: calc(100% - 50px);
  height: calc(100% - 60px);
  // padding: 18px 25px 0;
  width: calc(100% - 71px);
  margin: 18px 25px 0;
  background: #fff;
  border-radius: 15px;
  position: relative;
  padding: 20px 10px 10px 10px;
  overflow-y: auto;
  box-shadow: 0px 4px 30px rgba(232, 120, 145, 0.15);
}
</style>